body {
  background-color: $secondary;
}

h1,
th {
  font-family: 'Roboto Slab', serif;
  color: #ffffff;
}

.online {
  color: #7cfc00;
}

.offline {
  color: #ff0000;
}

.display {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.navbar.gray {
  background-color: $primary;
}

.imgCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  flex-grow: 1;
}

.coolingBorder {
  border-radius: 7px;
  border: 2px solid rgb(245, 245, 245);
}
